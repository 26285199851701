// .updateStore-page {
//     .form-card {
//       .form-fields-block {
//         display: flex;
//         flex-wrap: wrap;
//         margin-left: -15px;
//         margin-right: -15px;
//       }
      
//       .form-field {
//         width: 100%;
//         margin-bottom: 30px;
//         padding: 0 15px;
  
//         @media (min-width: 600px) {
//           width: 50%;
//         }
//       }
//     }
//   }
.storeUpdate-page {
    .form-card {
      .form-fields-block {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
      }
  
      .form-field {
        width: 100%;
        margin-bottom: 30px;
        padding: 0 15px;
  
        @media (min-width: 600px) {
          width: 50%;
        }
      }
    }
  }
  



  .question-section {
    margin-bottom: 20px;
  }
  
  .answer-section {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .field-section {
    margin: 10px 0;
  }
  
  input, select {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .completion-section {
    margin-top: 30px;
  }
  
  .submitted-answers {
    margin-top: 30px;
  }
  
  .submitted-answer {
    margin: 10px 0;
    padding: 10px;
    background-color: #e9e9e9;
    border-radius: 5px;
  }
  
  .error {
    color: red;
  }
  
  label {
    margin-right: 10px;
  }
  