// color variants
@import "../../assets//scss//theme-vars.module.scss";

.main-section {
  background-color: $secondaryLight;
  min-height: calc(100vh - 64px);
  position: relative;
}

/* SIDE DRAWER css starts */
.side-drawer-wrapper {
  .menu-list {
    .MuiListItemButton-root {
      .icons {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }

      &.Mui-selected {
        .home-menu-icon {
          background: url("../../assets//icons/home-menu-white-icon.svg");
        }

        .dashboard-menu-icon {
          background: url("../../assets//icons/dashboard-menu-white-icon.svg");
        }

        .manage-user-menu-icon {
          background: url("../../assets//icons/manage-user-menu-white-icon.svg");
        }

        .reports-menu-icon {
          background: url("../../assets//icons/reports-menu-white-icon.svg");
        }

        .category-icon {
          background: url("../../assets//icons/category-white-icon.svg");
        }
        .subcategory-icon  {
          background: url("../../assets//icons/subcategory_white_icon.svg");
        }
        .store-icon{
          background: url("../../assets//icons/store-white-icon.svg");
        }
        .product-icon {
          background: url("../../assets//icons/product-white-icon.svg");
        }
        .order-icon {
          background: url("../../assets//icons/order-white-icon.svg");
        }
        .order-list {
          background: url("../../assets//icons/order-list-white.svg");
        }
        .theme-icon {
          background: url("../../assets//icons/theme-white-icon.svg");
        }
        .theme-icon {
          background: url("../../assets//icons/theme-white-icon.svg");
        }
        .help-center-icon {
          background: url("../../assets//icons/help-center-white-icon.svg");
        }
      }
    }
  }
}

/* SIDE DRAWER css ends */

.notification-card {
  min-width: 300px;

  .card-header {
    background: $primaryMain;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #fff;
    padding-bottom: 0;

    .lead {
      margin: 0;
      font-size: 1.1rem;
      font-family: "Rubik", sans-serif;

      .count {
        font-size: 0.875rem;
        margin-left: 10px;
        font-family: "Rubik", sans-serif;
      }
    }

    .tabLinks-wrapper {
      margin-top: 10px;

      .MuiTabs-root {
        min-height: auto;
      }

      .MuiButtonBase-root {
        color: #fff;
        opacity: 0.75;
        min-height: auto;
        min-width: auto;
        padding: 15px 5px 12px;
        margin-right: 15px;
        text-transform: inherit;
        font-weight: normal;

        &.Mui-selected {
          opacity: 1;
          font-weight: 500;
        }
      }
      .MuiTabs-indicator {
        background-color: #fff;
      }
    }
  }
  .tab-panel {
    .item {
      cursor: pointer;
      margin: 0;
      padding: 15px 20px;
      transition: all 0.2s;
      font-size: 0.875rem;
      border-bottom: solid 1px #e7e7e7;

      &:hover {
        background: rgba(67, 116, 244, 0.1);
      }
    }
  }
}
