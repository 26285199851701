@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import "../../assets//scss//theme-vars.module.scss";

html,
body,
#root,
.App {
  height: 100%;
}

body {
  background: $background !important;
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem; // 14px
}

.page {
  padding: 20px;
  position: relative;
}

p {
  margin: 0;
}

.app-logo {
  width: 120px;
}

.hide {
  display: none;
}

.form-card {
  .form-field {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .link {
    color: $primaryMain;
    cursor: pointer;
    font-weight: 500;
  }
}

/* Scroll bar css */

::-webkit-scrollbar{
  width: 8px;
}

::-webkit-scrollbar-track{
  background-color: #fff;
}
::-webkit-scrollbar-thumb{
  background-color: lightgray;
  border-radius: 50px;
}

/* COMMON CSS -starts- */
.card-wrapper-default {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  position: relative;

  .card-title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .title {
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    font-family: "Rubik", sans-serif;
  }
}

.small-card-wrapper-default {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
  position: relative;

  .card-title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .title {
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    font-family: "Rubik", sans-serif;
  }
}

/* - resets - */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* COMMON CSS -ends- */

/* ICONS */

.svg-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
}
.icons,
.svg-icon-wrapper svg {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;



  &.factory {
    background-image: url("../icons/factory.png");
  }

  &.plant {
    background-image: url("../icons/plant.png");
  }

  &.weather-24c {
    background-image: url("../../assets/icons//weather-24c.svg");
  }

  &.weather-27c {
    background-image: url("../../assets/icons//weather-27c.svg");
  }

  &.weather-34c {
    background-image: url("../../assets/icons//weather-34c.svg");
  }

  &.weather-34c-white {
    background-image: url("../../assets/icons//weather-34c-white.svg");
  }

  &.energy-icon {
    background-image: url("../../assets/icons//energy-icon.svg");
  }

  &.energy-icon-black {
    background-image: url("../../assets/icons//energy-icon-black.svg");
  }

  &.dollar-icon {
    background-image: url("../../assets/icons/dollar-icon.svg");
  }

  &.solar-icon {
    background-image: url("../../assets/icons/solar-icon.svg");
  }

  &.home-icon {
    background-image: url("../../assets/icons/home-icon.svg");
  }

  &.battery-icon {
    background-image: url("../../assets/icons/battery-icon.svg");
  }

  &.eb-panel-icon {
    background-image: url("../../assets/icons/eb-panel-icon.svg");
  }

  &.eb-transformer-icon {
    background-image: url("../../assets/icons/eb-transformer-icon.svg");
  }

  &.home-menu-icon {
    background-image: url("../../assets/icons/home-menu-icon.svg");
  }

  &.dashboard-menu-icon {
    background-image: url("../../assets/icons/dashboard-menu-icon.svg");
  }

  &.manage-user-menu-icon {
    background-image: url("../../assets/icons/manage-user-menu-icon.svg");
  }

  &.notification-icon {
    background-image: url("../../assets/icons/notification-icon.svg");
  }

  &.reports-menu-icon {
    background-image: url("../../assets/icons/reports-menu-icon.svg");
  }

  &.help-center-icon {
    background-image: url("../../assets/icons/help-center-icon.svg");
  }

  &.theme-icon {
    background-image: url("../../assets/icons/theme-icon.svg");
  }

  &.category-icon {
    background-image: url("../../assets/icons/category-icon.svg");
  }

  &.store-icon {
    background-image: url("../../assets/icons/store-icon.svg");
  }

  &.product-icon {
    background-image: url("../../assets/icons/product-icon.svg");
  }

  &.subcategory-icon {
    background-image: url("../../assets/icons/subcategory_icon.svg");
  }

  &.order-icon {
    background-image: url("../../assets/icons/order-icon.svg");
  }
  
  &.order-list {
    background-image: url("../../assets/icons/order-list.svg");
  }
}

/* energyCard css -starts- */

.energy-card {
  .energy-icon,
  .energy-icon-black {
    width: 24px;
    height: 41px;
  }

  .solar-icon {
    width: 62px;
    height: 62px;
  }

  .dollar-icon {
    width: 42px;
    height: 66px;
  }
}

/* energyCard css -ends- */
