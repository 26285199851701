@import "../../assets//scss//theme-vars.module.scss";

.card-wrapper {
  background: $white;

  &.energy-card {
    border-radius: 12px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 25px;
    min-height: 160px;
    height: 100%;
    position: relative;

    &#c1 {
      // background: $white;
      background: #2E3192;
      color: #ffff;

      .item:nth-child(2) .value {
        color: $errorLight;
      }

      .solar-icon {
        flex-shrink: 0;
      }

      .item-list {
        @media (min-width: 1440px) {
          margin-left: 30px;
        }
      }

      .loader-wrapper .circular-loader {
        color: $darkTextTitle;
      }
    }

    &#c2 {
      // background: $primaryMain;
      background: #79B5BC;
      color: $white;
    }

    &#c3 {
      // background: $darkBlue;
      background: #008490;
      color: $white;
    }

    &#c4 {
      // background: $jungleGreen;
      background: #324B4E;
      color: $white;

      .item-list {
        margin-left: 0;
      }
    }

    .title {
      font-family: "Rubik", sans-serif;
      font-size: 1rem;
      font-weight: 600;
    }

    .card-content {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .icons {
        margin-right: 10px;

        svg {
          font-size: 54px;
        }
      }

      .item-list {
        margin-left: 20px;
        @media (min-width: 1200px) {
          margin-left: 0;
        }
        @media (min-width: 1440px) {
          margin-left: 10px;
        }
      }

      .item {
        display: flex;
        align-items: center;

        .value {
          font-size: 2rem;
          font-weight: 500;
          position: relative;
        }
        .text {
          margin-left: 8px;
          display: inline-block;
        }

        .value-suffix {
          position: absolute;
          bottom: 8px;
          right: -35px;
          display: inline-block;
          font-size: 0.875rem;
        }
      }
    }
    .helper-text {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}

.energy-card.corner-up {
  padding: 0;

  .card-header {
    padding-top: 20px;
  }

  .card-header,
  .card-content {
    z-index: 1;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.energy-card.corner-up::before {
  content: "";
  display: block;
  width: 100%;
  height: 60%;
  position: absolute;
  top: -10%;
  left: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 1;
}

.energy-card.corner-up.first-item.left-drawer-enabled::before {
  @media (min-width: 1024px) {
    transform: skewY(8deg);
  }

  @media (min-width: 1440px) {
    transform: skewY(7deg);
  }

  @media (min-width: 1536px) {
    transform: skewY(5deg);
  }

  @media (min-width: 2000px) {
    transform: skewY(4deg);
  }

  @media (min-width: 2400px) {
    transform: skewY(2deg);
  }

  @media (min-width: 3400px) {
    transform: skewY(4deg);
  }
}

.energy-card.corner-up.last-item.left-drawer-enabled::before {
  @media (min-width: 1024px) {
    transform: skewY(-8deg);
  }

  @media (min-width: 1440px) {
    transform: skewY(-6deg);
  }

  @media (min-width: 1536px) {
    transform: skewY(-5deg);
  }

  @media (min-width: 2000px) {
    transform: skewY(-4deg);
  }

  @media (min-width: 2400px) {
    transform: skewY(-2deg);
  }

  @media (min-width: 3400px) {
    transform: skewY(-4deg);
  }
}

.energy-card.corner-up.first-item::before {
  transform: skewY(19deg);
  background-color: #2E3192;

  @media (min-width: 1024px) {
    transform: skewY(6deg);
  }

  @media (min-width: 1440px) {
    transform: skewY(6deg);
  }

  @media (min-width: 1536px) {
    transform: skewY(4deg);
  }

  @media (min-width: 2000px) {
    transform: skewY(4deg);
  }

  @media (min-width: 2400px) {
    transform: skewY(3deg);
  }

  @media (min-width: 3400px) {
    transform: skewY(4deg);
  }
}

.energy-card.corner-up.last-item::before {
  transform: skewY(-19deg);
  // background-color: $jungleGreen;
  background-color: #324B4E;

  @media (min-width: 1024px) {
    transform: skewY(-6deg);
  }

  @media (min-width: 1440px) {
    transform: skewY(-6deg);
  }

  @media (min-width: 1536px) {
    transform: skewY(-4deg);
  }

  @media (min-width: 2000px) {
    transform: skewY(-4deg);
  }

  @media (min-width: 2400px) {
    transform: skewY(-3deg);
  }

  @media (min-width: 3400px) {
    transform: skewY(-4deg);
  }
}
